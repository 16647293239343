<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top" style="border-bottom:#aaa 1px solid;">
            <header class="header navbar navbar-expand-sm" style="background:#fff;justify-content:normal;text-align:center;display:block;">
                <img src="@/assets/images/yamasa_logo.gif" style="height:80%;">
            </header>
        </div>
        <!--  END NAVBAR  -->
    </div>
</template>
<script>
    export default {
        data() {
            return {
                selectedLang: null,
                countryList: this.$store.state.countryList
            };
        },
        mounted() {
            //this.selectedLang = this.$appSetting.toggleLanguage();

            //this.toggleMode();
        },
        methods: {
            toggleMode(mode) {
                this.$appSetting.toggleMode(mode);
            },

            //changeLanguage(item) {
                //this.selectedLang = item;
                //this.$appSetting.toggleLanguage(item);
            //}
        }
    };
</script>
