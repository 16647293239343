import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    //dashboard
    { path: '/', name: 'Home', component: Home },

    {
        path: '/app-login',
        name: 'app-login',
        component: () =>
            import ( /* webpackChunkName: "app-login" */ '../views/app/login.vue'),
        meta: { layout: 'auth' }

    },

    {
        path: '/app-membername',
        name: 'app-membername',
        component: () =>
            import ( /* webpackChunkName: "app-membername" */ '../views/app/member_name.vue'),
        meta: { layout: 'auth' }

    },

    {
        path: '/app-main',
        name: 'app-main',
        component: () =>
            import ( /* webpackChunkName: "app-main" */ '../views/app/main.vue')
    },


    {
        path: '/app-receipt',
        name: 'app-receipt',
        component: () =>
            import ( /* webpackChunkName: "app-receipt" */ '../views/app/receipt.vue')
    },

];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;